<template>
  <div class="container">
    <!-- tab选项卡列表 -->
    <van-tabs
      v-model="activeName"
      sticky
      line-width="24"
      line-height="4"
      title-inactive-color="#AFAFAF"
      title-active-color="#AB8438"
      background="#f0eadf"
      color="#AB8438"
      @change="handleTabs"
    >
      <van-tab
        v-for="(item, index) in articleCategoryList"
        :key="index"
        :title="item.name"
        :name="item.id"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('noMore')"
          :loading-text="$t('loadingText')"
          :error.sync="error"
          :error-text="$t('requestFailed')"
          :immediate-check="false"
          @load="getArticlePageList"
        >
          <folding-panel :article-page-list="articlePageList" />
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import {
  getArticleCategoryList,
  getArticlePageList,
} from "@/api/essenceMustKnow/index";

export default {
  name: "essenceMustKnow",
  data() {
    return {
      activeName: 1,
      // tab选项卡列表数据
      articleCategoryList: [],
      // tab选项卡数据数组的长度
      articleCategoryListLen: 0,
      // tab-item-content数据列表
      articlePageList: [],
      // 上拉加载更多参数---start
      params: {
        categoryId: undefined,
        pageNum: 1,
        pageSize: 10,
      },
      loading: false,
      finished: false,
      error: false,
      // 上拉加载更多参数---end
    };
  },
  mounted() {
    this.getArticleCategoryList();
  },
  methods: {
    // 获取tab选项卡列表数据
    getArticleCategoryList() {
      getArticleCategoryList().then((res) => {
        if (200 === res.code) {
          this.articleCategoryListLen = res.data.length;
          res.data.push({
            name: this.$t("all"),
            id: this.articleCategoryListLen + 1,
          });
          this.articleCategoryList = res.data;
          this.params.categoryId = res.data[0].id;
          this.getArticlePageList();
        }
      });
    },
    // 获取每个tab的content数据列表
    getArticlePageList() {
      getArticlePageList(this.params).then((res) => {
        if (200 === res.code) {
          // 终止当前单次加载请求
          this.loading = false;

          this.params.pageNum++;
          this.articlePageList.push(...res.data.list);

          // 单次请求数据全部加载完成如果是最后一页，则终止加载数据
          if (res.data.total === this.articlePageList.length) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      });
    },
    // 监听tabs选择切换选中事件
    handleTabs() {
      this.resetData();
    },
    // 重置
    resetData() {
      this.articlePageList = [];
      console.log(this.articlePageList);
      this.loading = false;
      this.finished = false;
      this.error = false;
      this.params = {
        categoryId:
          this.activeName === this.articleCategoryListLen + 1
            ? undefined
            : this.activeName,
        pageNum: 1,
        pageSize: 10,
      };
      this.getArticlePageList();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
}
/deep/ {
  .van-tabs {
    height: 100%;
  }
  .van-tab {
    font-size: 0.32rem;
  }
  .van-tabs__content {
    height: calc(100% - 0.88rem);
    background: #fbf7f3;
    padding: 0.66rem 0.48rem 0.56rem;
    overflow: auto;

    .van-cell {
      padding: 0.28rem;
    }
  }
}
</style>
