import request, { baseUrl } from "@/utils/request";

/*
 *@描述: 获取转提攻略下精华必知列表tab数据集合
 *@参数: 无
 *@作者: 金涛
 *@日期: 2021-07-06 11:24:23
 */
export function getArticleCategoryList() {
  return request({
    url: `${baseUrl.api}/article/getArticleCategoryList`,
    method: "GET",
    headers: {
      needToken: true,
    },
  });
}

/*
 *@描述: 获取文章内容分页列表
 *@参数: data: {
    categoryId: 分类ID
    pageNum: 当前页	
    pageSize: 每页大小
 }
 *@作者: 金涛
 *@日期: 2021-07-06 15:39:39
 */
export function getArticlePageList(data) {
  return request({
    url: `${baseUrl.api}//article/getArticlePageList`,
    method: "POST",
    headers: {
      needToken: true,
    },
    data,
  });
}
